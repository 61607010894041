import React, { useState, useEffect, useRef } from 'react';

import { IKrannichPortalData } from './krannich-portal.data';
import MsDyn365, { IActionContext, ICookieContext, ICoreContext } from '@msdyn365-commerce/core';
import actionAddressFromZipCode, { GetAddressFromZipCodeInput } from '../../actions/get-address-from-zip-code.action';
import { IKrannichPortalProps, IKrannichPortalConfig, IKrannichPortalResources } from './krannich-portal.props.autogenerated';
import { getMainDomain } from '../../utils/helper';

export interface IModalRouterPortalProps {
    actionContext: IActionContext;
    cookies: ICookieContext;
    resources: IKrannichPortalResources;
    config: IKrannichPortalConfig;
    context: ICoreContext;
}

interface IZipCode {
    Locality: string;
    Pcode: number;
    State: string;
    Territory: string;
}

/**
 * KrannichPortal functional component
 */

const KrannichPortal: React.FC<IKrannichPortalProps<IKrannichPortalData>> = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [zipCode, setZipCode] = useState('');
    const [error, setError] = useState('');
    const [alertActive, setAlertActive] = useState(false);
    const modalBtn = useRef<any>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const enableGenericTerritories = props.config.enableGenericTerritories || false;

    const territory_data = props.config?.territoryData!;
    const notFoundError =
        props?.context?.actionContext?.requestContext?.apiSettings?.oun !== props.context.app.config.ounNumberForUsSite
            ? props.resources.notFound
            : props.resources.notFoundZipcode;

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const addressFromZip = props.context.request.cookies.get<IZipCode>('addressFromZip');

        if (!enableGenericTerritories) {
            if (addressFromZip.status === 'FOUND') {
                const { value } = addressFromZip;
                const territory = value?.Territory;
                let isTerritoryMatched;
                const pathName = props?.context?.actionContext?.requestContext?.url?.requestUrl?.pathname;
                const hostName = props?.context?.actionContext?.requestContext?.url?.requestUrl?.hostname;
                if (
                    territory === 'KAUM' &&
                    (pathName?.includes(territory.toLocaleLowerCase()) || pathName?.includes('aus-mb') || hostName?.includes('aus-mb'))
                ) {
                    isTerritoryMatched = true;
                } else if (
                    territory === 'KAUB' &&
                    (pathName?.includes(territory.toLocaleLowerCase()) || pathName?.includes('aus-br') || hostName?.includes('aus-br'))
                ) {
                    isTerritoryMatched = true;
                } else {
                    isTerritoryMatched = false;
                }
                !isTerritoryMatched && __reditectTo(territory);
            } else {
                toggle();
            }
        } else {
            if (addressFromZip.status === 'FOUND' && territory_data?.length > 0) {
                const { value } = addressFromZip;
                const territory = value?.Territory;

                let isTerritoryMatched;
                const pathName = props?.context?.actionContext?.requestContext?.url?.requestUrl?.pathname;
                const hostName = props?.context?.actionContext?.requestContext?.url?.requestUrl?.hostname;

                for (const item of territory_data) {
                    if (
                        territory === item.name &&
                        (pathName?.includes(territory.toLocaleLowerCase()) ||
                            pathName?.includes(item.pathKeyword) ||
                            hostName?.includes(item.pathKeyword))
                    ) {
                        isTerritoryMatched = true;
                        break;
                    }
                }
                !isTerritoryMatched && __reditectTo(territory ?? '');
            } else {
                toggle();
            }
        }
    }, []);

    const __reditectTo = (territory: string | undefined) => {
        if (!enableGenericTerritories) {
            const { brBaseUrl, mbBaseUrl } = props.config;
            let redirectURL = null;
            if (territory?.toUpperCase() === 'KAUM') {
                redirectURL = mbBaseUrl;
            }
            if (territory?.toUpperCase() === 'KAUB') {
                redirectURL = brBaseUrl;
            }
            MsDyn365.isBrowser && (window.location.href = redirectURL!);
        } else {
            let redirectURL = '';
            const matchingTerritory = territory_data?.find(item => item.name === territory?.toUpperCase());

            if (matchingTerritory) {
                redirectURL = matchingTerritory.redirectURL;
                MsDyn365.isBrowser && (window.location.href = redirectURL!);
            } else {
                toggle();
            }
        }
    };

    const _handleZipCode = async (event: React.FormEvent) => {
        if (!enableGenericTerritories) {
            const { apiException } = props.resources;
            event.preventDefault();
            if (zipCode.length === 0) {
                setError(notFoundError);
                setAlertActive(true);
            }
            try {
                const getAddressFromZipCode = await actionAddressFromZipCode(
                    new GetAddressFromZipCodeInput(zipCode, props.config.defaultTerritory),
                    props.context.actionContext
                );
                if (Object.keys(getAddressFromZipCode).length === 0) {
                    setError(notFoundError);
                    setAlertActive(true);
                } else {
                    if (MsDyn365.isBrowser) {
                        const currentHostname = window.location.hostname;
                        // TODO: List of known production hostnames which we will make dynamic after Go Alive.
                        const productionHostnames = [
                            // Todo: for local testing, need to remove later on
                            // 'localhost:4000',
                            'aus.krannich-shop.com',
                            'aus-mb.krannich-shop.com',
                            'aus-br.krannich-shop.com'
                        ];
                        const isProdEnv = productionHostnames.includes(currentHostname);
                        if (isProdEnv) {
                            props?.context?.request?.cookies.set<string>('addressFromZip', JSON.stringify(getAddressFromZipCode), {
                                domain: '.krannich-shop.com',
                                path: '/'
                            });
                        } else {
                            props?.context?.request?.cookies.set<string>('addressFromZip', JSON.stringify(getAddressFromZipCode));
                        }
                        setIsOpen(false);
                        setAlertActive(false);
                        __reditectTo(getAddressFromZipCode?.Territory);
                    }
                }
            } catch (e) {
                setError(apiException);
                setAlertActive(true);
            }
            setZipCode('');
        } else {
            event.preventDefault();
            if (zipCode.length === 0) {
                setError(notFoundError);
                setAlertActive(true);
            }
            try {
                const getAddressFromZipCode = await actionAddressFromZipCode(
                    new GetAddressFromZipCodeInput(zipCode, props.config.defaultTerritory),
                    props.context.actionContext
                );
                if (Object.keys(getAddressFromZipCode).length === 0) {
                    setError(notFoundError);
                    setAlertActive(true);
                } else {
                    if (MsDyn365.isBrowser) {
                        const currentHostname = window.location.hostname;
                        const mainDomain = getMainDomain(currentHostname);
                        if (currentHostname === 'localhost') {
                            props.context.request.cookies.set<string>('addressFromZip', JSON.stringify(getAddressFromZipCode));
                        } else if (mainDomain) {
                            props.context.request.cookies.set<string>('addressFromZip', JSON.stringify(getAddressFromZipCode), {
                                domain: `.${mainDomain}`,
                                path: '/'
                            });
                        } else {
                            props.context.request.cookies.set<string>('addressFromZip', JSON.stringify(getAddressFromZipCode), {
                                domain: '.krannich-shop.com',
                                path: '/'
                            });
                        }
                        setIsOpen(false);
                        setAlertActive(false);
                        __reditectTo(getAddressFromZipCode?.Territory);
                    }
                }
            } catch (e) {
                setError(props.resources.apiException);
                setAlertActive(true);
            }
            setZipCode('');
        }
    };
    const viewProps = {
        ...props,
        isOpen,
        inputRef,
        zipCode,
        setZipCode,
        error,
        alertActive,
        toggle,
        _handleZipCode,
        modalBtn
    };
    return props.renderView(viewProps) as React.ReactElement;
};

export default KrannichPortal;
